export class ReviewUser {
    constructor(
        public name: string = '',
        public icon: string = '',
        public review: string = '',
        public position: string = '',
        public lastname: string = '',
        public company: string = '',
    ) {

    }

}

