import { Component, OnInit } from '@angular/core';
import {DataService} from '@services/data.service';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
  encapsulation: 2
})
export class ClientComponent implements OnInit {

  clients: any = [];


  ngOnInit() {
    this.serviceData.GetClient().subscribe(resp => {
      this.clients = resp;

      setTimeout(() => {
        ($('#client-owl') as any).owlCarousel({
          loop: true,
          margin: 0,
          nav: false,
          dots: false,
          navText: ['<', '>'],
          responsiveClass: true,
          responsive: {
            0: {
              items: 2,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 5,
            }
          }
        });

      }, 100);
    });
  }

  owlnext() {

    $('.ftco-client .client-owl .owl-next').click();
  }
  owlprev() {
    $('.ftco-client .client-owl .owl-prev').click();
  }
  ngAfterViewInit() {


  }
  constructor(private serviceData: DataService, private config: ConfigService) { }

}
