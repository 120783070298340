import { Component, OnInit } from '@angular/core';
import {HelperService} from '@services/helper.service';
import {DataService} from '../../services/data.service';
import {ConfigService} from '../../services/config.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})


export class ServiceComponent implements OnInit {

  services = null;

   ngOnInit() {

     this.servService.GetService()
      .subscribe(resp => {
        this.services = resp;
      });

  }

  constructor(
    private http: HttpClient,
    private servService: DataService,
    private helper: HelperService,
    private config: ConfigService) {

  }

}
