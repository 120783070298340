import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  GetService(): Observable<any> {

    const data: any = [
      { id: "1", title: "POS Solutions", "description": "CUBETIQ POS is one of the best POS solutions designed for the needs of restaurants, bars, coffee shops, dining venues, retail and more. Our POS System is very easy to use and implement. CUBETIQ POS is designed to be compatible with systems and equipment such as cash drawers, barcode scanners and receipt printers.", "summary": "CUBETIQ provides top-notch Point of Sales solutions for small and medium businesses worldwide", "icon": "/assets/icons/POS.png", },
      { id: "2", title: "Window Applications", "description": "Your business needs a reliable enterprise software solution to support your many operations. It needs to scale, coordinate other software and mobile apps, incorporate advance tech, and cover an extensive list of other objectives. CUBETIQ has the experience, tools, and technologies to create such demanding custom software.", "summary": "We design and develop a variety of applications for Windows including specialized and custom enterprise software", "icon": "/assets/icons/Windows.png", },
      { id: "3", title: "Web Development", "description": "We empower our clients with adaptive web applications built using open-source and proprietary web technologies and practices to simplify complex business workflows. We focus on a convergence of technology and consulting to create innovative web applications delivering secure, scalable and intuitive functionality to your end users.", "summary": "Our company also design and develop a variety of websites using the latest and proven web technologies", "icon": "/assets/icons/Web.png", },
      { id: "4", title: "UX & UI Design", "description": "We offer custom software development and consulting services to help you pull off your next project with less stress and less cost. Our team has the technology prowess to power you at every stage of your product development lifecycle, from discovery to ongoing post-launch enhancement.", "summary": "Our team of UX designers creates easy-to-understand interfaces for all kinds of applications", "icon": "/assets/icons/vector.png", },
      { id: "5", title: "QA & Testing", "description": "We offer custom software development and consulting services to help you pull off your next project with less stress and less cost. Our team has the technology prowess to power you at every stage of your product development lifecycle, from discovery to ongoing post-launch enhancement.", "summary": "We pay a lot of attention to QA and Testing procedures to ensure the best quality of our software", "icon": "/assets/icons/bug.png", },
      { id: "6", title: "Maintainance & Support", "description": "Our dedicated support team will help our customers. Software support contracts include remote troubleshooting and support provided via the telephone and online channels, as well as installation assistance and basic usability assistance. Software support services do not include the purchase of subscriptions that provide entitlement and rights to use future minor versions (point releases) or future major releases of software.", "summary": "We are commited to service excellence and maintaining lasting partnerships with our customers We will continue evolve at the same place as your business", "icon": "/assets/icons/Custom.png" }
    ]

    return new Observable(observer => {
      observer.next(data);
      observer.complete();
    });

    // return this.http.get(`${this.config.ROOT_API}/services`);
  }

  GetClient() {
    return new Observable(observer => {
      observer.next([]);
      observer.complete();
    });

    // return this.http.get(`${this.config.ROOT_API}/clients`);

    // return [
    //   {
    //     heading: 'POS Solution',
    //     icon: '/assets/images/services-1.jpg',
    //   },
    //   {
    //     heading: 'Enterprise Software Development',
    //     icon: '/assets/images/services-2.jpg',
    //   },
    //   {
    //     heading: 'web development',
    //     icon: '/assets/images/services-3.jpg',
    //   },
    //   {
    //     heading: 'customization',
    //     icon: '/assets/images/services-4.jpg',
    //   },
    //   {
    //     heading: 'maintainance & support',
    //     icon: '/assets/images/services-5.jpg',
    //   },
    // ];
  }

  getSolutions(): Observable<any> {
    const data: any = [

      {
        id: "1",
        title: "Restaurant",
        icon: "/assets/icons/restaurant.png",
      },
      {
        id: "2",
        title: "CAFE",
        icon: "/assets/icons/cafe_1.png",
      },
      {
        id: "3",
        title: "Hotel",
        icon: "/assets/icons/hotel.png",
      },
      {
        id: "4",
        title: "Apartment & Condo",
        icon: "/assets/icons/Condo_1.png",
      },
      {
        id: "5",

        title: "School",
        icon: "/assets/icons/school.png",
      },
      {
        id: "6",

        title: "Clinic",
        icon: "/assets/icons/heart.png",
      },
      {
        id: "7",
        title: "Retail & Wholesale",
        icon: "/assets/icons/retail_1.png",
      },
      {
        id: "8",
        title: "Vehicles Dealerships",
        icon: "/assets/icons/car.png",
      },
      {
        id: "9",
        title: "Electronic Store",
        icon: "/assets/icons/electronic.png",
      },
      {
        id: "10",
        title: "Gym & Sports Club",
        icon: "/assets/icons/gym.png",
      },
      {
        id: "11",
        title: "Spa & Salon",
        icon: "/assets/icons/spa2.png",
      },
      {
        id: "12",
        title: "Pawn Shop",
        icon: "/assets/icons/pawnshop.png",
      },
      {
        id: "13",
        title: "Loan",
        icon: "/assets/icons/loan.png",
      },
      {
        id: "14",
        title: "Money Exchange",
        icon: "/assets/icons/money%20transfer_1.png",
      },
      {
        id: "15",
        title: "Production",
        icon: "/assets/icons/production_1.png",
      }
    ]

    return new Observable(observer => {
      observer.next(data);
      observer.complete();
    });

    // return this.http.get(`${this.config.ROOT_API}/solutions`);

    // return await this.http.get(`${this.config.ROOT_API}/solutions`)

    // return [
    //   {name : 'Restaurant', src: '/assets/ICON/restaurant.png'},
    //   {name : 'CAFE', src: '/assets/ICON/cafe_1.png'},
    //   {name : 'Hotel', src: '/assets/ICON/hotel.png'},
    //   {name : 'Apartment & Condo', src: '/assets/ICON/Condo_1.png'},
    //   {name : 'School', src: '/assets/ICON/school.png'},
    //   {name : 'Clinic', src: '/assets/ICON/heart.png'},
    //   {name : 'Retail & Wholesale', src: '/assets/ICON/retail_1.png'},
    //   {name : 'Vehicles Dealerships', src: '/assets/ICON/car.png'},
    //   {name : 'Electronic Store', src: '/assets/ICON/electronic.png'},
    //   {name : 'Gym & Sports Club', src: '/assets/ICON/gym.png'},
    //   {name : 'Spa & Salon', src: '/assets/ICON/spa2.png'},
    //   {name : 'Pawn Shop', src: '/assets/ICON/pawnshop.png'},
    //   {name : 'Loan', src: '/assets/ICON/loan.png'},
    //   {name : 'Money Exchange', src: '/assets/ICON/money%20transfer_1.png'},
    //   {name : 'Production', src: '/assets/ICON/production_1.png'},
    //
    // ];

  }

  getReason(): Observable<any[]> {
    const data = [
      { id: "1", title: "High Quality Hardware", "description": "", summary: "We use top-notch hardware to develop the most efficient apps for our customers", icon: "/assets/icons/hardware.png", },
      { id: "2", title: "Dedicated Support", "description": "", summary: "You can rely on our tech support that will gladly solve any app issue you may have", icon: "/assets/icons/Support.png", },
      { id: "3", title: "We Take Security Seriously", "description": "", summary: "The Security of Your money and personal data is our utmost priority", icon: "/assets/icons/security.png", },
      { id: "4", title: "Agile and Fast Working Style", "description": "", summary: "This type of approach to our work helps our specialists to quickly develop better apps", icon: "/assets/icons/speed.png", },
      { id: "5", title: "High Level of Usability", "description": "", summary: "All our products have high usability allowing users to easily operate the apps", icon: "/assets/icons/like.png", },
      { id: "6", title: "Scalable", "description": "", summary: "Meeting the demands of a growing business, We can quickly deployed in one store as easily as it can be in thousands", icon: "/assets/icons/store.png", }
    ]

    return new Observable(observer => {
      observer.next(data);
      observer.complete();
    });

    // return this.http.get<any[]>(`${this.config.ROOT_API}/reasons`);

    // return [
    //   {
    //     heading: 'High Quality Hardware',
    //     icon: '/assets/ICON/hardware.png',
    //     summary: 'We use top-notch hardware to develop the most efficient apps for our customers',
    //     desc: ''
    //   },
    //   {
    //     heading: 'Dedicated Support',
    //     icon: '/assets/ICON/Support.png',
    //     summary: 'You can rely on our tech support that will gladly solve any app issue you may have',
    //     desc: ''
    //   },
    //   {
    //     heading: 'We Take Security Seriously',
    //     icon: '/assets/ICON/security.png',
    //     summary: 'The Security of Your money and personal data is our utmost priority',
    //     desc: ''
    //   },
    //   {
    //     heading: 'Agile and Fast Working Style',
    //     icon: '/assets/ICON/speed.png',
    //     summary: 'This type of approach to our work helps our specialists to quickly develop better apps',
    //     desc: ''
    //   },
    //   {
    //     heading: 'High Level of Usability',
    //     icon: '/assets/ICON/like.png',
    //     summary: 'All our products have high usability allowing users to easily operate the apps',
    //     desc: ''
    //   },
    //   {
    //     heading: 'Scalable',
    //     icon: '/assets/ICON/store.png',
    //     summary: 'Meeting the demands of a growing business, We can quickly deployed in one store as easily as it can be in thousands',
    //     desc: ''
    //   },
    // ];
  }

  getPricing() {
    return [
      {
        title: 'ONE-TIME',
        desc: 'One time payment for a lifetime license.'
      },
      {
        title: 'Monthly',
        desc: 'Monthly Subscription <hr />Free support and updates'
      }
    ];
  }

  GetReviews() {
    return this.http.get(`${this.config.ROOT_API}/reviews`);
  }

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }
}
