import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AvatarModule} from 'ngx-avatar';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layouts/header/header.component';
import {FooterComponent} from './layouts/footer/footer.component';
import {HomeComponent} from './pages/home/home.component';
import {ServiceComponent} from './pages/service/service.component';
import {SolutionComponent} from './pages/solution/solution.component';
import {TestimonialComponent} from './pages/testimonial/testimonial.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {ClientComponent} from './pages/client/client.component';
import {ReasonComponent} from './pages/reason/reason.component';
import {ContactusComponent} from './pages/contactus/contactus.component';
import {NotFound404Component} from './pages/not-found404/not-found404.component';
import {FormsModule} from '@angular/forms';
import {CustomHttpInterceptor} from './Inject/HttpIntercept';

import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ProjectComponent} from './pages/project/project.component';
import {AboutusComponent} from './pages/aboutus/aboutus.component';
import {RequestdemoComponent} from './pages/requestdemo/requestdemo.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        ServiceComponent,
        SolutionComponent,
        TestimonialComponent,
        PricingComponent,
        ClientComponent,
        ReasonComponent,
        ContactusComponent,
        NotFound404Component,
        ProjectComponent,
        AboutusComponent,
        RequestdemoComponent
    ],
    imports: [
        BrowserModule,
        // FragmentPolyfillModule.forRoot({
        //     smooth: true
        // }),
        FormsModule,
        HttpClientModule,
        AvatarModule,
        AppRoutingModule,
        LazyLoadImageModule,
        ScrollToModule.forRoot()
    ],
    providers: [
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
