import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import * as swal from 'sweetalert2';
import {SweetAlertOptions, SweetAlertType} from 'sweetalert2';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clonedRequest = req.clone();
    // console.log('request interceptor', clonedRequest);

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest)
      .pipe(
        map(r => {
          // console.log('response interceptor', r);
          return r;
        }),
        catchError(err => {
          const response = err;
          const status = response.status;

          let option: SweetAlertOptions;

          switch (status) {
            case 422:
              const errors = response.error.errors;
              const msgs = Object.keys(errors)
                  .reduce((result, el) => {
                    return {...result, [el]: errors[el][0]};
                  }, {});
              const msg_html = Object.keys(errors)
                  .reduce((result, el) => {
                    return result.concat(`<br />${errors[el][0]}<br /> `);
                  }, '');
              option = {
                title: 'Validate Failed',
                html: `<div class="error">${msg_html}</div>`,
                type: 'warning'
              };
              break;
            case 500:
              option = {
                title: status,
                text: err.error,
                type: 'error'
              };
              break;

          }

          if (option !== null || option !== undefined) {
            swal['fire'](option);
          }

          return Promise.reject(err);
        })
      );

  }
}
