import { Component, OnInit } from '@angular/core';
import {HelperService} from '@services/helper.service';
import {DataService} from '../../services/data.service';
import {ConfigService} from '../../services/config.service';


@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.css']
})
export class ReasonComponent implements OnInit {

  reasons = [];

  constructor(private reasonService: DataService, private helper: HelperService, private config: ConfigService) {
    this.helper.setTitle('Why Choose UIs');
  }

  ngOnInit() {
    this.reasonService.getReason()
      .subscribe(resp => {
        this.reasons = Object.values(resp);
      });

  }

}
