import { Component, OnInit } from '@angular/core';

import {HelperService} from "@services/helper.service";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
})
export class PricingComponent implements OnInit {



  pricings=[]

  test=[]
  ngOnInit() {
    this.pricings=this.priceservice.getPricing()
  }


  constructor(private priceservice:DataService,private helper:HelperService) {
    this.helper.setTitle("Pricing")

  }
}
