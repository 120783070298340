import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@services/config.service';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import * as swal from 'sweetalert2';
import {SweetAlertOptions} from 'sweetalert2';
import {HelperService} from '@services/helper.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor(private http: HttpClient, private config: ConfigService, private helper: HelperService) { }

  submit(f: NgForm) {
    if (f.invalid) {
      return;
    }

    this.http.post(`${this.config.ROOT_API}/contact`, f.value)
      .subscribe(resp => {
        const options: SweetAlertOptions = {
          title: 'Success',
          text: 'Please Wait for we contact you!',
          type: 'success'
        };
        swal['fire'](options);
        f.resetForm({});
      });
    const a = document.createElement('a');
    // a.href = `mailto:hi@cubetiqs.com?subject=${f.value.email}&body=${encodeURI(f.controls.message.value)}`;
    //
    // a.href = `mailto:hi@cubetiqs.com?subject=${f.value.subject}&body=Hello%0D%0AWorld`;

    a.click();

  }
  ngOnInit(): void {
      this.helper.setTitle('Contact Us');

  }

}
