import { Component, OnInit } from '@angular/core';
import {HelperService} from '@services/helper.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(private helper:HelperService) { }

  ngOnInit() {
    this.helper.setTitle("About")

  }

  ngAfterViewInit(): void {
  }
}
