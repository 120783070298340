import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HelperService} from '@services/helper.service';
import {DataService} from '@services/data.service';
import {ReviewUser} from '../../Models/review-user';
import {FormControl, FormControlName, NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../services/config.service';
import {validate} from 'codelyzer/walkerFactory/walkerFn';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css'],
  encapsulation: 2
})
export class TestimonialComponent implements OnInit, AfterViewInit {

  constructor(
    private testimonialService: DataService,
    private helper: HelperService,
    private http: HttpClient,
    private config: ConfigService
  ) {
    this.helper.setTitle('Testimonial')
    ;

  }

  testimonials:any = [];

  model: ReviewUser = new ReviewUser();
  errs: any = {};

  rand = true;

  owlnext() {
    $('.ftco-testimonial .owl-nav .owl-next').click();
  }
  owlprev() {
    $('.ftco-testimonial .owl-nav .owl-prev').click();
  }


  uploadimage() {
    // console.log('bef', fileinput);
    const fileinput = document.createElement('input');
    // fileinput.addAttribute('type','file');

    fileinput.type = 'file';
    fileinput.accept="image/x-png,image/gif,image/jpeg"
    // fileinput.name = 'file';
    fileinput.onchange = (e) => {
      this.model.icon = '';
      const formData = new FormData();
      formData.append('file', e['path'][0].files[0]);
      this.http.post(`${this.config.ROOT_API}/media/save`, formData)
        .subscribe(resp => {
          this.model.icon = resp['location'];
        });
    };

    fileinput.click();
  }

  validate(): boolean {
    this.errs = {};
    if (!this.model.name.trim()) {
      this.errs['name'] = 'Firstname Required';
      return false;
    }
    if (!this.model.name.trim()) {
      this.errs['lastname'] = 'Lastname Required';
      return false;
    }
    if (!this.model.position.trim()) {
      this.errs['position'] = 'Position Required';
      return false;
    }

    if (!this.model.review.trim()) {
      this.errs['review'] = 'Review Message Required';
      return false;
    }

    return true;
  }

  submit(form: NgForm) {
    // const model = new ReviewUser(...form.value);
    // console.log('submit',form)

    if (!this.validate()) {
      return;
    }
    this.http.post(`${this.config.ROOT_API}/review`, this.model)
      .subscribe(resp => {
        this.rand = false;
        this.model = new ReviewUser();

        setTimeout(() => {
          this.rand = true;
          this.ngOnInit();
        }, 100);
      });
  }

  ngOnInit() {
    this.testimonialService.GetReviews()
      .subscribe(resp => {
        this.testimonials = resp;

        setTimeout(() => {

          ($('#testmonial-owl') as any).owlCarousel({
            loop: false,
            margin: 10,
            nav: true,
            navText: ['<', '>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                nav: false
              },
              600: {
                items: 2,
                nav: false
              },
              1000: {
                items: 3,
                nav: false,
              }
            }
          });
        }, 100);
      });
  }


  ngAfterViewInit() {

    // ($('#modalreview') as any).modal('toggle');


    Array.from(document.querySelectorAll('.owl-dots button'), function (el: HTMLElement) {
      el.innerHTML = '';
      el.style.borderRadius = '50%';
    });


  }
}
