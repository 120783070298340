import {AfterContentInit, Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // template:''
})
export class AppComponent implements AfterContentInit{
  title = 'CUBETIQ';

  loader(){
    // Remove old scripts
    // document.querySelector("script[src='scripts.js']").remove()


    // window.location.reload()

    // let h=location.hash
    // console.log("test");
    // switch(h) {
    //   case "#service":
    //     console.log(h);
    //     window.scrollTo(0, 1000);
    //     break;
    //   case "#why_us":
    //     console.log(h);
    //     break;
    //   default:
    //     window.scrollTo(0, 0);
    //     console.log("Invalit");
    // }

  }

  ngAfterContentInit(): void {

  }

}
