import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  setTitle(text:string){
    this.titleService.setTitle(text + " - CUBETIQ: Software Solutions for successful owners")
  }

  link(url="/",payload={}){
    this.router.navigate([url])
  }

  constructor(
    private titleService:Title,
    private router:Router
  ) { }
}
