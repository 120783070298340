import {NgModule} from '@angular/core';
import {Routes, RouterModule, Router, NavigationStart} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {ServiceComponent} from './pages/service/service.component';
import {SolutionComponent} from './pages/solution/solution.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {TestimonialComponent} from './pages/testimonial/testimonial.component';
import {ContactusComponent} from './pages/contactus/contactus.component';
import {NotFound404Component} from './pages/not-found404/not-found404.component';
import {AboutusComponent} from './pages/aboutus/aboutus.component';
import {RequestdemoComponent} from './pages/requestdemo/requestdemo.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },

  {
    path: 'home',
    component: HomeComponent
  },

  {
    path: 'service',
    component: ServiceComponent
  },

  {
    path: 'solution',
    component: SolutionComponent
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'testimonial',
    component: TestimonialComponent
  },
  {
    path: 'contactus',
    component: ContactusComponent
  },
  {
    path: 'aboutus',
    component: AboutusComponent
  },
  {
    path: 'requestdemo',
    component: RequestdemoComponent
  },
  {
    path: '404',
    component: NotFound404Component
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule],
})

export class AppRoutingModule {
  constructor(router: Router) {

    window.scrollTo(0, 0);
    router.events.subscribe(route=>{
      // console.log('enter',route)
      if(route instanceof NavigationStart){

      }
    })


  }
}
