import {AfterViewInit, Component, OnInit} from '@angular/core';
import Typewriter from 'typewriter-effect/dist/core';
import {HelperService} from '../../services/helper.service';
import {Router, RouterLink} from '@angular/router';
import * as swal from 'sweetalert2';
import {SweetAlertOptions} from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit ,OnInit{


  title = 'Home';

  requestdemo(){
    this.router.navigate(['/contactus',{}])
    // this.router.navigateByUrl('/contactus')
    // new RouterLink().replaceUrl('/contactus')
  }

  getquote(){
   let options:SweetAlertOptions={
     text:"Please Contact us to get information",
     type:"info",
     title:"Get Quote"
   };
   swal['fire'](options);
  }

  // ngOnInit() {
  //   new Typewriter('#typewriter', {
  //     strings: ['CUBETIQ. ', 'The best innovation for your business.'],
  //     autoStart: true,
  //     loop: true,
  //   }).pauseFor(1000);
  // }

  ngOnInit(): void {
    this.helper.setTitle('Home');

  }

  ngAfterViewInit() {
    if ($('#ftco-loader').length > 0) {
      $('#ftco-loader').removeClass('show');
    }

    (<any>$('.slider-carousel')).owlCarousel({
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplay: true,
    center: true,
    loop: true,
    items: 1,
    margin: 0,
    stagePadding: 0,
    nav: false,
    dots: false,
    navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  });

  setTimeout(() => {
    let hash=location.hash
    switch(hash){
      case "#service":
        $('html, body').animate({
          'scrollTop':   $('#service').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
        }, 600);
      break;
      case "#why_us":
        $('html, body').animate({
          'scrollTop':   $('#why_us').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
        }, 600);
      break;
    }
  }, 1000);

  }
  constructor(private helper: HelperService,private router:Router) {

  }

}
