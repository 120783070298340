import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  ROOT_API = 'https://cubetiq-crm-website.deno.dev';

  ROOT = '/';

  constructor() { }
}
