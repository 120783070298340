import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import {HelperService} from '@services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})


export class HeaderComponent implements OnInit, AfterViewInit {

  show_navbar = false;
  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('li.nav-item').click(() => {
      $('.navbar-toggler').click();
    });

    $('.nav-item').on('click', function() {
      $('.navbar-nav').find('.active').removeClass('active');
      $(this).addClass('active');
   });

    // let h=location.hash
    // switch(h) {
    //   case "#service":
    //     console.log(h);
    //     window.scrollTo(0, 1000);
    //     break;
    //   case "#why_us":
    //     console.log(h);
    //     break;
    //   default:
    //     window.scrollTo(0, 0);
    //     console.log("Invalit");
    // }

  }

  public ScrollToService() {

    $('html, body').animate({
      'scrollTop':   $('#service').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
    }, 1000, () => {
      this.helper.setTitle('Services');
    });
  }


  public ScrollToWhyus() {
    $('html, body').animate({
      'scrollTop':   $('#why_us').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
    }, 1000, () => {
      this.helper.setTitle('Why Us');
    });
  }

  public ScrollToProject() {
    this.helper.setTitle('Home');

    $('html, body').animate({
      'scrollTop':   $('#project').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
    }, 1000);
  }

  public ScrollToHome() {
    $('html, body').animate({
      'scrollTop':   $('#home').offset().top - document.getElementById('ftco-navbar').offsetHeight - 20
    }, 500);
  }

  // constructor() { }
  constructor(private _scrollToService: ScrollToService, private helper: HelperService) { }




}




