import { Component, OnInit } from '@angular/core';
import {HelperService} from '@services/helper.service';
import {DataService} from '../../services/data.service';
import {ConfigService} from '../../services/config.service';


@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.css'],
})

export class SolutionComponent implements OnInit {

  solutions = [];

  constructor(private services: DataService, private helper: HelperService,private config:ConfigService) {
    this.helper.setTitle('Solution');

  }

  ngOnInit() {
    this.services.getSolutions()
      .subscribe(resp => {
      this.solutions = Object.values(resp);
    });
  }


}
