import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@services/config.service';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import * as swal from 'sweetalert2';
import {SweetAlertOptions} from 'sweetalert2';
import {HelperService} from '@services/helper.service';


@Component({
    selector: 'app-requestdemo',
    templateUrl: './requestdemo.component.html',
    styleUrls: ['./requestdemo.component.css']
})
export class RequestdemoComponent implements OnInit, AfterViewInit {

    constructor(private http: HttpClient, private config: ConfigService, private helper: HelperService) {
    }

    submitRequest(f: NgForm) {
        // if (f.invalid) {
        //   return;
        // }

        swal['fire']({
            onBeforeOpen() {
                swal['showLoading']();
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
            // text:"sdfs",
            showConfirmButton: false,
        });

        this.http.post(`${this.config.ROOT_API}/requestdemo`, f.value)
            .subscribe(resp => {
                const options: SweetAlertOptions = {
                    title: 'Success',
                    text: 'Please Wait for we contact you!',
                    type: 'success'
                };
                swal['fire'](options);
                f.resetForm({});
            });
        const a = document.createElement('a');
        // a.href = `mailto:hi@cubetiqs.com?subject=${f.value.companyname}&body=${encodeURI(f.controls.about.value)}`;
        // a.href = `mailto:soungsokheang@gmail.com?subject=${f.value.companyname}&body=${encodeURI(f.controls.about.value)}`;
        // //
        // a.href = `mailto:hi@cubetiqs.com?subject=${f.value.subject}&body=Hello%0D%0AWorld`;
        a.click();
    }

    ngAfterViewInit(): void {

        $('.country option').each((index, element: HTMLInputElement) => {
            element.value = element.innerText;
        });
    }

    ngOnInit(): void {
        this.helper.setTitle('Request-Demo');
    }

}
